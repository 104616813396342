var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    ref: "overviewTab",
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_name.id,
      "value": _vm.data.user_name,
      "label": _vm.controls.user_name.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.report_date.id,
      "value-single": _vm.data.report_date,
      "label": _vm.controls.report_date.label,
      "readonly": _vm.controls.report_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.department.id,
      "value": _vm.data.department,
      "label": _vm.controls.department.label,
      "readonly": _vm.controls.department.readonly,
      "options": _vm.controls.department.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.category.id,
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.exportContent
    }
  }, [_vm._v(" Export Content ")])], 1)], 1), _vm.mode === _vm.$constants.FORM_MODE.CREATE ? _c('b-row', [_c('b-col', [_c('label', {
    staticClass: "col-form-label"
  }, [_vm._v("Reporting Periods")]), _vm.isSubmittedReportsLoading ? _c('clip-loader', {
    staticClass: "ml-3 mt-2",
    attrs: {
      "size": 14,
      "color": "#36D7B7",
      "loading": true
    }
  }) : _vm._e(), !_vm.isSubmittedReportsLoading ? _c('div', {
    staticClass: "mt-3"
  }, _vm._l(_vm.reportsForSubmit, function (report, index) {
    return _c('b-button', {
      key: "rep-".concat(index),
      attrs: {
        "squared": "",
        "variant": report.submitted ? 'success' : 'outline-dark'
      },
      on: {
        "click": function click($event) {
          report.submitted ? report.open(report.submitted) : report.method(report.period);
        }
      }
    }, [_vm._v(" " + _vm._s(report.periodTitle) + " ")]);
  }), 1) : _vm._e()], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.from_date.id,
      "value-single": _vm.data.from_date,
      "label": _vm.controls.from_date.label,
      "readonly": true,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.to_date.id,
      "value-single": _vm.data.to_date,
      "label": _vm.controls.to_date.label,
      "readonly": true,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1)], 1), _vm.data.category && _vm.data.category.label === 'Daily Production Report' ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_vm._v("Report content")]), _c('div', {
    staticClass: "daily-content p-2",
    domProps: {
      "innerHTML": _vm._s(_vm.data.view_content)
    }
  })])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_vm.data.category && _vm.data.category.label === 'Daily Production Report' ? _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_vm._v("Append report")]) : _vm._e(), _vm.data.category && _vm.data.category.label !== 'Daily Production Report' ? _c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": _vm.controls.editor.id
    }
  }, [_vm._v("Report content")]) : _vm._e(), _c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.editor.id,
      "editor": _vm.editor,
      "disabled": _vm.controls.editor.readonly,
      "config": _vm.controls.editor.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.edit_content,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "edit_content", $$v);
      },
      expression: "data.edit_content"
    }
  })], 1)], 1)], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e()], 1), _c('hr'), _vm.data.reviewed_by ? _c('span', [_c('b', [_vm._v("Reviewed:")]), _vm._v(" " + _vm._s(_vm.data.reviewed_by))]) : _vm._e(), _vm.data.reviewed_at ? _c('span', [_vm._v(" on " + _vm._s(_vm.data.reviewed_at))]) : _vm._e(), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      },
      "custom-buttons": [{
        text: 'Mark as Reviewed',
        icon: 'signature',
        loading: false,
        visible: !_vm.data.reviewed && _vm.isReviewer,
        method: 'reviewed',
        variant: 'warning'
      }]
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Reports submission'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Report submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Report submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }